import React from "react";
import "./App.css";
import Router from "./Router";
import { Amplify } from "aws-amplify";
import { config, awsconfig } from "./config";
const { prod } = config;

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: awsconfig.aws_appsync_graphqlEndpoint,
      region: awsconfig.aws_appsync_region,
      // Set the default auth mode to "apiKey" and provide the API key value
      defaultAuthMode: "apiKey",
      apiKey: awsconfig.aws_appsync_apiKey,
    },
  },
  Auth: {
    Cognito: {
      userPoolClientId: prod.APP_CLIENT_ID,
      userPoolId: prod.USER_POOL_ID,
      loginWith: {
        username: "true",
        email: "false",
        phone: "false",
      },
    },
  },
});

function App() {
  return (
    <>
      <React.StrictMode>
        <Router />
      </React.StrictMode>
    </>
  );
}

export default App;
