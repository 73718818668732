import { useEffect } from "react";
import { onCreateOrder, onUpdateOrder } from "../graphql/subscriptions";
import { apiClient } from "../Router";

const openNotification = (message, api) => {
  api.info({
    message,
    placement: "bottomLeft",
  });
};
const useOrderSubscription = ({
  dispatch,
  api,
  accountId,
  userAttributes,
  userLocation,
}) => {
  useEffect(() => {
    const handleSubscription = ({
      operation,
      name,
      type,
      notification,
      filter,
    }) => {
      return apiClient
        .graphql({
          query: operation,
          variables: {
            filter,
          },
        })
        .subscribe({
          next: (orderData) => {
            const order = orderData.data[`${name}`];
            if (
              !userAttributes.admin &&
              order?.orderSource?.sectionName?.toLowerCase() !==
                userLocation.toLowerCase()
            )
              return;
            dispatch({ type, order });
            openNotification(notification, api);
          },
          error: (error) =>
            console.log(
              `error: ${operation.name}Subscription`,
              error?.error?.errors[0],
            ),
        });
    };

    const subscribe = () => {
      const newOrderSubscription = handleSubscription({
        operation: onCreateOrder,
        name: "onCreateOrder",
        type: "ADD_ORDER",
        notification: "New order placed",
        filter: {
          accountId: {
            eq: accountId,
          },
          status: {
            eq: "placed",
          },
        },
      });
      const updateOrderSubscription = handleSubscription({
        operation: onUpdateOrder,
        name: "onUpdateOrder",
        type: "UPDATE_ORDER",
        notification: "Order updated",
        filter: {
          accountId: {
            eq: accountId,
          },
        },
      });

      return { newOrderSubscription, updateOrderSubscription };
    };

    let subscriptions = subscribe();

    const handleOnline = () => {
      subscriptions.newOrderSubscription.unsubscribe();
      subscriptions.updateOrderSubscription.unsubscribe();
      subscriptions = subscribe();
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOnline);

    return () => {
      subscriptions.newOrderSubscription.unsubscribe();
      subscriptions.updateOrderSubscription.unsubscribe();
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOnline);
    };
  }, [dispatch, accountId]);
};

export default useOrderSubscription;
