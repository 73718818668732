import React, { createContext } from "react";
import { ConfigProvider } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { generateClient } from "@aws-amplify/api";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { AppHeader, AppFooter, Menus, Orders } from "./components";
import "@aws-amplify/ui-react/styles.css";
import { usePartner } from "./hooks";

export const apiClient = generateClient();
export const AppContext = createContext();

const Router = ({ user }) => {
  const contextValue = usePartner(user, apiClient);
  const getRoutes = () => {
    if (contextValue.loading) {
      return [{ path: "*", element: <div>Loading...</div> }];
    }

    const routes = [];
    const { partner, userAttributes } = contextValue;
    const isOrderingEnabled = partner?.config?.ordering;
    const isAdmin = userAttributes?.admin;

    if (isOrderingEnabled) {
      routes.push({
        path: "/orders",
        element: <Orders />,
      });

      if (!isAdmin) {
        routes.push({
          path: "/",
          element: <Orders />,
        });
      }
    }

    if (isAdmin) {
      routes.push({
        path: "/",
        element: <Menus />,
      });
      routes.push({
        path: "/menus",
        element: <Menus />,
      });
    }

    // Ensure at least one route is always returned
    if (routes.length === 0) {
      routes.push({
        path: "*",
        element: <div>Page not found</div>,
      });
    }

    return routes;
  };

  const router = createBrowserRouter(getRoutes());

  return (
    <AppContext.Provider value={contextValue}>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "rgba(57, 47, 47, 1)",
            borderRadius: 2,

            // Alias Token
            // colorBgContainer: 'rgba(57, 47, 47, 1);',
          },
        }}
      >
        <AppHeader />
        <RouterProvider router={router} />
        <AppFooter />
      </ConfigProvider>
    </AppContext.Provider>
  );
};

export default withAuthenticator(Router, {
  hideSignUp: true,
  usernameAlias: "email",
  withGreetings: true,
});
