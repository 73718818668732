export const formatCurrency = ({ price, language, currency }) => {
  return new Intl.NumberFormat(language, {
    style: "currency",
    currency,
  }).format(price);
};
export const getDisplayPrice = ({
  priceInPence = 0,
  withCurrencySymbol = false,
  currencyConfig,
}) => {
  const price = Number(priceInPence / 100);

  let config = currencyConfig;

  if (typeof currencyConfig === "string") {
    config = JSON.parse(currencyConfig);
  }

  if (withCurrencySymbol) {
    return formatCurrency({
      ...config,
      price,
    });
  }

  return price;
};

export const getMenuFilterOptions = (menus = []) => {
  const options = menus.reduce((acc, menu) => {
    if (menu && menu.subCategoryName) {
      acc[menu.subCategoryName] = (acc[menu.subCategoryName] || 0) + 1;
    }
    return acc;
  }, {});

  return options;
};

export function getDifference(oldMenu, newMenu) {
  const result = {};

  for (const key in oldMenu) {
    if (newMenu.hasOwnProperty(key)) {
      if (JSON.stringify(oldMenu[key]) !== JSON.stringify(newMenu[key])) {
        result[key] = { oldValue: oldMenu[key], newValue: newMenu[key] };
      }
    } else {
      result[key] = { oldValue: oldMenu[key], newValue: undefined };
    }
  }

  for (const key in newMenu) {
    if (!oldMenu.hasOwnProperty(key)) {
      result[key] = { oldValue: undefined, newValue: newMenu[key] };
    }
  }

  return result;
}
