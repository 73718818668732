import { useState, useEffect, useMemo, useCallback } from "react";
import { partnerByAccountId } from "../graphql/queries";
import { signOut, fetchAuthSession } from "aws-amplify/auth";

const usePartner = (user, apiClient) => {
  const [partner, setPartner] = useState(null);
  const [userAttributes, setUserAttributes] = useState(null);
  const [loading, setLoading] = useState(true);

  const isAdmin = useCallback((idToken) => {
    const groups = idToken.payload["cognito:groups"];
    return groups?.includes("admin") || false;
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const {
          tokens: { accessToken, idToken },
        } = await fetchAuthSession({
          bypassCache: true,
        });

        if (!idToken || !accessToken) return;

        const admin = isAdmin(idToken);

        setUserAttributes({
          "custom:accountId": idToken.payload["custom:accountId"],
          "custom:location": idToken.payload["custom:location"],
          admin,
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user:", error);
        setLoading(false);
      }
    };

    fetchUser();
  }, [isAdmin]);

  const fetchPartners = useCallback(async () => {
    if (!userAttributes) return;
    try {
      const { data } = await apiClient.graphql({
        query: partnerByAccountId,
        variables: {
          accountId: userAttributes["custom:accountId"],
          limit: 1000,
        },
      });

      setPartner(data?.partnerByAccountId?.items[0] || null);
    } catch (e) {
      console.error(e);
    }
  }, [apiClient, userAttributes]);

  useEffect(() => {
    if (userAttributes) {
      fetchPartners();
    }
  }, [userAttributes, fetchPartners]);

  const contextValue = useMemo(
    () => ({
      partner,
      user,
      userAttributes,
      loading,
      signOut,
    }),
    [partner, user, userAttributes, loading],
  );

  return contextValue;
};

export default usePartner;
