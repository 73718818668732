// utils.js

export function extractValueFromUrl(urlString) {
  if (!urlString.startsWith("http://") && !urlString.startsWith("https://")) {
    urlString = "http://" + urlString;
  }

  const url = new URL(urlString);
  return url.searchParams.get("t");
}

export const getLocalTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return date.toLocaleTimeString([], options);
};

export const getTheLatestTime = (sessionData) => {
  if (sessionData.length === 0) {
    return "No scan data";
  }
  const latestTime = sessionData.reduce((acc, curr) => {
    const time = curr.createdAt;
    return time > acc ? time : acc;
  }, sessionData[0].createdAt);
  return getLocalTime(latestTime);
};

export const normalizeQrcodeLinks = (qrcodeLinks, selectedDay) => {
  const date = convertToDateIsoString(selectedDay);
  const links = qrcodeLinks.map((qrcodeLink) => {
    const { qrcodeId, sessionData = [], updatedAt } = qrcodeLink;
    const scanCount = sessionData
      .filter((data) => data.createdAt.startsWith(date))
      .reduce((acc, curr) => acc + curr.scanCount, 0);
    return {
      table: extractValueFromUrl(qrcodeId),
      scanCount,
      lastTimeScanned: getTheLatestTime(sessionData),
      updatedAt,
    };
  });

  return links
    .sort((a, b) => b.scanCount - a.scanCount)
    .filter((link) => link.scanCount > 0);
};

export const convertToDateIsoString = (day = "today") => {
  const date = new Date();
  if (day === "yesterday") {
    date.setDate(date.getDate() - 1);
  }
  return date.toISOString().split("T")[0];
};
