import React from "react";
import { Form, Input, Modal } from "antd";
import { getDisplayPrice } from "../../../../utils";
import { capitalizeText } from "../../../../utils";

const MenuModal = ({
  open,
  handleOk,
  confirmLoading,
  handleCancel,
  selectedMenu,
  currencyConfig,
}) => {
  const [form] = Form.useForm();
  const menuFields = ["name", "description"];

  if (selectedMenu.locationPrices) {
    menuFields.push("locationPrices");
  } else {
    menuFields.push("price");
  }
  const getMenuPrices = (values) => {
    return Object.keys(values).reduce((acc, key) => {
      if (key.includes("locationPrice")) {
        const location = key.substring(14);
        const price = convertToPence(values[key]);
        acc.push({
          location,
          price, // convert to pence or kobo
        });
      }
      return acc;
    }, []);
  };
  const onFinish = (values) => {
    const updatedFields = menuFields.reduce((acc, key) => {
      if (key === "locationPrices") {
        acc.locationPrices = getMenuPrices(values);
      } else {
        acc[key] = key === "price" ? convertToPence(values[key]) : values[key];
      }
      return acc;
    }, {});
    const updatedValues = {
      ...selectedMenu,
      ...updatedFields,
    };

    const { locationPrices = [] } = updatedValues;
    if (locationPrices && locationPrices.length > 0) {
      const bushBarPrice = locationPrices.find(
        (locationPrice) => locationPrice.location === "bush-bar", // set price to bush-bar price as backward compatibility
      );

      if (bushBarPrice) {
        updatedValues.price = bushBarPrice.price; // set price to bush-bar price as backward compatibility
      }
    }

    handleOk({
      ...updatedValues,
    });
  };
  const convertToPence = (value) => String(Number(value) * 100);
  const currencySymbol = currencyConfig.symbol || "";

  const renderLocationPriceItem = (locationPrice, index) => {
    const initialValue = getDisplayPrice({
      priceInPence: locationPrice.price,
      withCurrencySymbol: false,
      currencyConfig,
    });
    return (
      <Form.Item
        key={`prices-${index}`}
        label={capitalizeText(`${locationPrice.location}`)}
        name={`locationPrice-${locationPrice.location}`}
        initialValue={initialValue}
        data-test-id="menu-edit-form-input"
        rules={[{ required: true, message: "Please input prices!" }]}
      >
        <Input
          prefix={currencySymbol}
          data-test-menu-id={selectedMenu.id}
          name="prices"
          type="number"
          className="no-zoom"
        />
      </Form.Item>
    );
  };

  const renderPriceItem = (price) => {
    const initialValue = getDisplayPrice({
      priceInPence: price,
      withCurrencySymbol: false,
      currencyConfig,
    });
    return (
      <Form.Item
        key={`price`}
        label={capitalizeText(`price`)}
        name={`price`}
        initialValue={initialValue}
        data-test-id="menu-edit-form-input"
        rules={[{ required: true, message: "Please input price!" }]}
      >
        <Input
          prefix={currencySymbol}
          data-test-menu-id={price}
          name="price"
          type="number"
          className="no-zoom"
        />
      </Form.Item>
    );
  };

  const renderFormItem = (key) => (
    <Form.Item
      key={key}
      label={key}
      name={key}
      initialValue={selectedMenu[key]}
      data-test-id="menu-edit-form-input"
      rules={[
        { required: key !== "description", message: `Please input ${key}!` },
      ]}
    >
      <Input
        prefix={key === "price" ? currencySymbol : ""}
        data-test-menu-id={selectedMenu.id}
        name={key}
        type={key === "price" ? "number" : "text"}
        className="no-zoom"
      />
    </Form.Item>
  );
  const getFormItems = () => {
    return menuFields.flatMap((key) => {
      if (key === "locationPrices") {
        return selectedMenu.locationPrices.map(renderLocationPriceItem);
      }

      if (key === "price") {
        return renderPriceItem(selectedMenu[key]);
      }
      return renderFormItem(key);
    });
  };

  return (
    <>
      {open ? (
        <Modal
          open={open}
          title={`Editing ${selectedMenu.name}`}
          onOk={form.submit}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          okText="Save"
          style={{
            width: "626px",
          }}
        >
          <>
            <Form
              name="basic"
              form={form}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: false }}
              onFinish={onFinish}
              autoComplete="off"
              data-test-id="menu-edit-form"
            >
              {getFormItems()}
            </Form>
          </>
        </Modal>
      ) : null}
    </>
  );
};

export default MenuModal;
