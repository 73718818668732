/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateOrderNumber = /* GraphQL */ `
  subscription OnCreateOrderNumber(
    $filter: ModelSubscriptionOrderNumberFilterInput
  ) {
    onCreateOrderNumber(filter: $filter) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const onUpdateOrderNumber = /* GraphQL */ `
  subscription OnUpdateOrderNumber(
    $filter: ModelSubscriptionOrderNumberFilterInput
  ) {
    onUpdateOrderNumber(filter: $filter) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const onDeleteOrderNumber = /* GraphQL */ `
  subscription OnDeleteOrderNumber(
    $filter: ModelSubscriptionOrderNumberFilterInput
  ) {
    onDeleteOrderNumber(filter: $filter) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const onCreateOrderCurrentNumber = /* GraphQL */ `
  subscription OnCreateOrderCurrentNumber(
    $filter: ModelSubscriptionOrderCurrentNumberFilterInput
  ) {
    onCreateOrderCurrentNumber(filter: $filter) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateOrderCurrentNumber = /* GraphQL */ `
  subscription OnUpdateOrderCurrentNumber(
    $filter: ModelSubscriptionOrderCurrentNumberFilterInput
  ) {
    onUpdateOrderCurrentNumber(filter: $filter) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteOrderCurrentNumber = /* GraphQL */ `
  subscription OnDeleteOrderCurrentNumber(
    $filter: ModelSubscriptionOrderCurrentNumberFilterInput
  ) {
    onDeleteOrderCurrentNumber(filter: $filter) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePaymentConfig = /* GraphQL */ `
  subscription OnCreatePaymentConfig(
    $filter: ModelSubscriptionPaymentConfigFilterInput
  ) {
    onCreatePaymentConfig(filter: $filter) {
      accountId
      paymentToken
      paymentProvider
      paymentEndpointUrl
      paymentCallbackUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePaymentConfig = /* GraphQL */ `
  subscription OnUpdatePaymentConfig(
    $filter: ModelSubscriptionPaymentConfigFilterInput
  ) {
    onUpdatePaymentConfig(filter: $filter) {
      accountId
      paymentToken
      paymentProvider
      paymentEndpointUrl
      paymentCallbackUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePaymentConfig = /* GraphQL */ `
  subscription OnDeletePaymentConfig(
    $filter: ModelSubscriptionPaymentConfigFilterInput
  ) {
    onDeletePaymentConfig(filter: $filter) {
      accountId
      paymentToken
      paymentProvider
      paymentEndpointUrl
      paymentCallbackUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder($filter: ModelSubscriptionOrderFilterInput) {
    onCreateOrder(filter: $filter) {
      id
      name
      status
      tableNumber
      accountId
      sales {
        accountId
        userClientId
        productId
        name
        price
        quantity
        __typename
      }
      customerDetails {
        roomNumber
        tableNumber
        name
        email
        phone
        __typename
      }
      paymentDetails {
        method
        amount
        currency
        status
        __typename
      }
      waiterDetails {
        waiterID
        name
        __typename
      }
      orderSource {
        type
        name
        sectionName
        __typename
      }
      estimatedTime
      feedback {
        rating
        comment
        __typename
      }
      orderNumber
      customerPhoneNumber
      smsNotificationToPartnerSent
      smsNotificationToCustomerSent
      pushNotificationToPartnerSent
      sessionId
      paymentStatus
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder($filter: ModelSubscriptionOrderFilterInput) {
    onUpdateOrder(filter: $filter) {
      id
      name
      status
      tableNumber
      accountId
      sales {
        accountId
        userClientId
        productId
        name
        price
        quantity
        __typename
      }
      customerDetails {
        roomNumber
        tableNumber
        name
        email
        phone
        __typename
      }
      paymentDetails {
        method
        amount
        currency
        status
        __typename
      }
      waiterDetails {
        waiterID
        name
        __typename
      }
      orderSource {
        type
        name
        sectionName
        __typename
      }
      estimatedTime
      feedback {
        rating
        comment
        __typename
      }
      orderNumber
      customerPhoneNumber
      smsNotificationToPartnerSent
      smsNotificationToCustomerSent
      pushNotificationToPartnerSent
      sessionId
      paymentStatus
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder($filter: ModelSubscriptionOrderFilterInput) {
    onDeleteOrder(filter: $filter) {
      id
      name
      status
      tableNumber
      accountId
      sales {
        accountId
        userClientId
        productId
        name
        price
        quantity
        __typename
      }
      orderNumber
      customerPhoneNumber
      smsNotificationToPartnerSent
      smsNotificationToCustomerSent
      pushNotificationToPartnerSent
      paymentStatus
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSections = /* GraphQL */ `
  subscription OnCreateSections($filter: ModelSubscriptionSectionsFilterInput) {
    onCreateSections(filter: $filter) {
      id
      name
      accountId
      numberOfAttendants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSections = /* GraphQL */ `
  subscription OnUpdateSections($filter: ModelSubscriptionSectionsFilterInput) {
    onUpdateSections(filter: $filter) {
      id
      name
      accountId
      numberOfAttendants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSections = /* GraphQL */ `
  subscription OnDeleteSections($filter: ModelSubscriptionSectionsFilterInput) {
    onDeleteSections(filter: $filter) {
      id
      name
      accountId
      numberOfAttendants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAttendant = /* GraphQL */ `
  subscription OnCreateAttendant(
    $filter: ModelSubscriptionAttendantFilterInput
  ) {
    onCreateAttendant(filter: $filter) {
      id
      accountId
      firstName
      lastName
      phoneNumber
      gender
      sections
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAttendant = /* GraphQL */ `
  subscription OnUpdateAttendant(
    $filter: ModelSubscriptionAttendantFilterInput
  ) {
    onUpdateAttendant(filter: $filter) {
      id
      accountId
      firstName
      lastName
      phoneNumber
      gender
      sections
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAttendant = /* GraphQL */ `
  subscription OnDeleteAttendant(
    $filter: ModelSubscriptionAttendantFilterInput
  ) {
    onDeleteAttendant(filter: $filter) {
      id
      accountId
      firstName
      lastName
      phoneNumber
      gender
      sections
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMenu = /* GraphQL */ `
  subscription OnCreateMenu($filter: ModelSubscriptionMenuFilterInput) {
    onCreateMenu(filter: $filter) {
      id
      accountId
      description
      name
      inStock
      category
      imageUrl
      subCategoryName
      categoryName
      price
      availabilityTime
      toppings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMenu = /* GraphQL */ `
  subscription OnUpdateMenu($filter: ModelSubscriptionMenuFilterInput) {
    onUpdateMenu(filter: $filter) {
      id
      accountId
      description
      name
      inStock
      category
      imageUrl
      subCategoryName
      categoryName
      price
      locationPrices {
        location
        price
        __typename
      }
      availabilityTime
      availableLocations
      toppings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMenu = /* GraphQL */ `
  subscription OnDeleteMenu($filter: ModelSubscriptionMenuFilterInput) {
    onDeleteMenu(filter: $filter) {
      id
      accountId
      description
      name
      inStock
      category
      imageUrl
      subCategoryName
      categoryName
      price
      availabilityTime
      toppings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTopping = /* GraphQL */ `
  subscription OnCreateTopping($filter: ModelSubscriptionToppingFilterInput) {
    onCreateTopping(filter: $filter) {
      id
      accountId
      display_name
      maximum_selection_quantity
      minimum_selection_quantity
      prompt_label
      topping_is_complimentary
      category
      slug
      topping_is_required
      topping_options {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      menuToppingsId
      __typename
    }
  }
`;
export const onUpdateTopping = /* GraphQL */ `
  subscription OnUpdateTopping($filter: ModelSubscriptionToppingFilterInput) {
    onUpdateTopping(filter: $filter) {
      id
      accountId
      display_name
      maximum_selection_quantity
      minimum_selection_quantity
      prompt_label
      topping_is_complimentary
      category
      slug
      topping_is_required
      topping_options {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      menuToppingsId
      __typename
    }
  }
`;
export const onDeleteTopping = /* GraphQL */ `
  subscription OnDeleteTopping($filter: ModelSubscriptionToppingFilterInput) {
    onDeleteTopping(filter: $filter) {
      id
      accountId
      display_name
      maximum_selection_quantity
      minimum_selection_quantity
      prompt_label
      topping_is_complimentary
      category
      slug
      topping_is_required
      topping_options {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      menuToppingsId
      __typename
    }
  }
`;
export const onCreateToppingOption = /* GraphQL */ `
  subscription OnCreateToppingOption(
    $filter: ModelSubscriptionToppingOptionFilterInput
  ) {
    onCreateToppingOption(filter: $filter) {
      id
      accountId
      topping_option_in_stock
      topping_option_name
      topping_option_price
      createdAt
      updatedAt
      toppingTopping_optionsId
      __typename
    }
  }
`;
export const onUpdateToppingOption = /* GraphQL */ `
  subscription OnUpdateToppingOption(
    $filter: ModelSubscriptionToppingOptionFilterInput
  ) {
    onUpdateToppingOption(filter: $filter) {
      id
      accountId
      topping_option_in_stock
      topping_option_name
      topping_option_price
      createdAt
      updatedAt
      toppingTopping_optionsId
      __typename
    }
  }
`;
export const onDeleteToppingOption = /* GraphQL */ `
  subscription OnDeleteToppingOption(
    $filter: ModelSubscriptionToppingOptionFilterInput
  ) {
    onDeleteToppingOption(filter: $filter) {
      id
      accountId
      topping_option_in_stock
      topping_option_name
      topping_option_price
      createdAt
      updatedAt
      toppingTopping_optionsId
      __typename
    }
  }
`;
export const onCreatePartner = /* GraphQL */ `
  subscription OnCreatePartner($filter: ModelSubscriptionPartnerFilterInput) {
    onCreatePartner(filter: $filter) {
      partnerName
      slug
      accountId
      devices {
        nextToken
        __typename
      }
      config {
        ordering
        payment
        autoConfirm
        customerSmsNotificationIsEnabled
        partnerSmsNotificationIsEnabled
        partnerPhoneNumber
        pushNotificationEnabled
        live
        clientSlug
        sectionNameMapping
        appConfirmationUrl
        bannerImage
        searchText
        currencyConfig
        categories
        openingHours
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePartner = /* GraphQL */ `
  subscription OnUpdatePartner($filter: ModelSubscriptionPartnerFilterInput) {
    onUpdatePartner(filter: $filter) {
      partnerName
      slug
      accountId
      devices {
        nextToken
        __typename
      }
      config {
        ordering
        payment
        autoConfirm
        customerSmsNotificationIsEnabled
        partnerSmsNotificationIsEnabled
        partnerPhoneNumber
        pushNotificationEnabled
        live
        clientSlug
        sectionNameMapping
        appConfirmationUrl
        bannerImage
        searchText
        currencyConfig
        categories
        openingHours
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePartner = /* GraphQL */ `
  subscription OnDeletePartner($filter: ModelSubscriptionPartnerFilterInput) {
    onDeletePartner(filter: $filter) {
      partnerName
      slug
      accountId
      devices {
        nextToken
        __typename
      }
      config {
        ordering
        payment
        autoConfirm
        customerSmsNotificationIsEnabled
        partnerSmsNotificationIsEnabled
        partnerPhoneNumber
        pushNotificationEnabled
        live
        clientSlug
        sectionNameMapping
        appConfirmationUrl
        bannerImage
        searchText
        currencyConfig
        categories
        openingHours
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePartnerDevice = /* GraphQL */ `
  subscription OnCreatePartnerDevice(
    $filter: ModelSubscriptionPartnerDeviceFilterInput
  ) {
    onCreatePartnerDevice(filter: $filter) {
      deviceId
      pushToken
      accountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePartnerDevice = /* GraphQL */ `
  subscription OnUpdatePartnerDevice(
    $filter: ModelSubscriptionPartnerDeviceFilterInput
  ) {
    onUpdatePartnerDevice(filter: $filter) {
      deviceId
      pushToken
      accountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePartnerDevice = /* GraphQL */ `
  subscription OnDeletePartnerDevice(
    $filter: ModelSubscriptionPartnerDeviceFilterInput
  ) {
    onDeletePartnerDevice(filter: $filter) {
      deviceId
      pushToken
      accountId
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const onCreateAuditLog = /* GraphQL */ `
  subscription OnCreateAuditLog($filter: ModelSubscriptionAuditLogFilterInput) {
    onCreateAuditLog(filter: $filter) {
      id
      accountId
      changeTime
      user
      field
      update
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAuditLog = /* GraphQL */ `
  subscription OnUpdateAuditLog($filter: ModelSubscriptionAuditLogFilterInput) {
    onUpdateAuditLog(filter: $filter) {
      id
      accountId
      changeTime
      user
      field
      update
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAuditLog = /* GraphQL */ `
  subscription OnDeleteAuditLog($filter: ModelSubscriptionAuditLogFilterInput) {
    onDeleteAuditLog(filter: $filter) {
      id
      accountId
      changeTime
      user
      field
      update
      createdAt
      updatedAt
      __typename
    }
  }
`;
