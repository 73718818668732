/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrderNumber = /* GraphQL */ `
  mutation CreateOrderNumber(
    $input: CreateOrderNumberInput!
    $condition: ModelOrderNumberConditionInput
  ) {
    createOrderNumber(input: $input, condition: $condition) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const updateOrderNumber = /* GraphQL */ `
  mutation UpdateOrderNumber(
    $input: UpdateOrderNumberInput!
    $condition: ModelOrderNumberConditionInput
  ) {
    updateOrderNumber(input: $input, condition: $condition) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const deleteOrderNumber = /* GraphQL */ `
  mutation DeleteOrderNumber(
    $input: DeleteOrderNumberInput!
    $condition: ModelOrderNumberConditionInput
  ) {
    deleteOrderNumber(input: $input, condition: $condition) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const createOrderCurrentNumber = /* GraphQL */ `
  mutation CreateOrderCurrentNumber(
    $input: CreateOrderCurrentNumberInput!
    $condition: ModelOrderCurrentNumberConditionInput
  ) {
    createOrderCurrentNumber(input: $input, condition: $condition) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrderCurrentNumber = /* GraphQL */ `
  mutation UpdateOrderCurrentNumber(
    $input: UpdateOrderCurrentNumberInput!
    $condition: ModelOrderCurrentNumberConditionInput
  ) {
    updateOrderCurrentNumber(input: $input, condition: $condition) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrderCurrentNumber = /* GraphQL */ `
  mutation DeleteOrderCurrentNumber(
    $input: DeleteOrderCurrentNumberInput!
    $condition: ModelOrderCurrentNumberConditionInput
  ) {
    deleteOrderCurrentNumber(input: $input, condition: $condition) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPaymentConfig = /* GraphQL */ `
  mutation CreatePaymentConfig(
    $input: CreatePaymentConfigInput!
    $condition: ModelPaymentConfigConditionInput
  ) {
    createPaymentConfig(input: $input, condition: $condition) {
      accountId
      paymentToken
      paymentProvider
      paymentEndpointUrl
      paymentCallbackUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePaymentConfig = /* GraphQL */ `
  mutation UpdatePaymentConfig(
    $input: UpdatePaymentConfigInput!
    $condition: ModelPaymentConfigConditionInput
  ) {
    updatePaymentConfig(input: $input, condition: $condition) {
      accountId
      paymentToken
      paymentProvider
      paymentEndpointUrl
      paymentCallbackUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePaymentConfig = /* GraphQL */ `
  mutation DeletePaymentConfig(
    $input: DeletePaymentConfigInput!
    $condition: ModelPaymentConfigConditionInput
  ) {
    deletePaymentConfig(input: $input, condition: $condition) {
      accountId
      paymentToken
      paymentProvider
      paymentEndpointUrl
      paymentCallbackUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      name
      status
      tableNumber
      accountId
      sales {
        accountId
        userClientId
        productId
        name
        price
        quantity
        __typename
      }
      customerDetails {
        roomNumber
        tableNumber
        name
        email
        phone
        __typename
      }
      paymentDetails {
        method
        amount
        currency
        status
        __typename
      }
      waiterDetails {
        waiterID
        name
        __typename
      }
      orderSource {
        type
        name
        sectionName
        __typename
      }
      estimatedTime
      feedback {
        rating
        comment
        __typename
      }
      orderNumber
      customerPhoneNumber
      smsNotificationToPartnerSent
      smsNotificationToCustomerSent
      pushNotificationToPartnerSent
      sessionId
      paymentStatus
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      name
      status
      tableNumber
      accountId
      sales {
        accountId
        userClientId
        productId
        name
        price
        quantity
        __typename
      }
      customerDetails {
        roomNumber
        tableNumber
        name
        email
        phone
        __typename
      }
      paymentDetails {
        method
        amount
        currency
        status
        __typename
      }
      waiterDetails {
        waiterID
        name
        __typename
      }
      orderSource {
        type
        name
        sectionName
        __typename
      }
      estimatedTime
      feedback {
        rating
        comment
        __typename
      }
      orderNumber
      customerPhoneNumber
      smsNotificationToPartnerSent
      smsNotificationToCustomerSent
      pushNotificationToPartnerSent
      sessionId
      paymentStatus
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      name
      status
      tableNumber
      accountId
      sales {
        accountId
        userClientId
        productId
        name
        price
        quantity
        __typename
      }
      customerDetails {
        roomNumber
        tableNumber
        name
        email
        phone
        __typename
      }
      paymentDetails {
        method
        amount
        currency
        status
        __typename
      }
      waiterDetails {
        waiterID
        name
        __typename
      }
      orderSource {
        type
        name
        sectionName
        __typename
      }
      estimatedTime
      feedback {
        rating
        comment
        __typename
      }
      orderNumber
      customerPhoneNumber
      smsNotificationToPartnerSent
      smsNotificationToCustomerSent
      pushNotificationToPartnerSent
      sessionId
      paymentStatus
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSections = /* GraphQL */ `
  mutation CreateSections(
    $input: CreateSectionsInput!
    $condition: ModelSectionsConditionInput
  ) {
    createSections(input: $input, condition: $condition) {
      id
      name
      accountId
      numberOfAttendants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSections = /* GraphQL */ `
  mutation UpdateSections(
    $input: UpdateSectionsInput!
    $condition: ModelSectionsConditionInput
  ) {
    updateSections(input: $input, condition: $condition) {
      id
      name
      accountId
      numberOfAttendants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSections = /* GraphQL */ `
  mutation DeleteSections(
    $input: DeleteSectionsInput!
    $condition: ModelSectionsConditionInput
  ) {
    deleteSections(input: $input, condition: $condition) {
      id
      name
      accountId
      numberOfAttendants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAttendant = /* GraphQL */ `
  mutation CreateAttendant(
    $input: CreateAttendantInput!
    $condition: ModelAttendantConditionInput
  ) {
    createAttendant(input: $input, condition: $condition) {
      id
      accountId
      firstName
      lastName
      phoneNumber
      gender
      sections
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAttendant = /* GraphQL */ `
  mutation UpdateAttendant(
    $input: UpdateAttendantInput!
    $condition: ModelAttendantConditionInput
  ) {
    updateAttendant(input: $input, condition: $condition) {
      id
      accountId
      firstName
      lastName
      phoneNumber
      gender
      sections
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAttendant = /* GraphQL */ `
  mutation DeleteAttendant(
    $input: DeleteAttendantInput!
    $condition: ModelAttendantConditionInput
  ) {
    deleteAttendant(input: $input, condition: $condition) {
      id
      accountId
      firstName
      lastName
      phoneNumber
      gender
      sections
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMenu = /* GraphQL */ `
  mutation CreateMenu(
    $input: CreateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    createMenu(input: $input, condition: $condition) {
      id
      accountId
      description
      name
      inStock
      category
      imageUrl
      subCategoryName
      categoryName
      price
      availabilityTime
      toppings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMenu = /* GraphQL */ `
  mutation UpdateMenu(
    $input: UpdateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    updateMenu(input: $input, condition: $condition) {
      id
      accountId
      description
      name
      inStock
      category
      imageUrl
      subCategoryName
      categoryName
      price
      locationPrices {
        location
        price
        __typename
      }
      availabilityTime
      availableLocations
      toppings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMenu = /* GraphQL */ `
  mutation DeleteMenu(
    $input: DeleteMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    deleteMenu(input: $input, condition: $condition) {
      id
      accountId
      description
      name
      inStock
      category
      imageUrl
      subCategoryName
      categoryName
      price
      availabilityTime
      toppings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTopping = /* GraphQL */ `
  mutation CreateTopping(
    $input: CreateToppingInput!
    $condition: ModelToppingConditionInput
  ) {
    createTopping(input: $input, condition: $condition) {
      id
      accountId
      display_name
      maximum_selection_quantity
      minimum_selection_quantity
      prompt_label
      topping_is_complimentary
      category
      slug
      topping_is_required
      topping_options {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      menuToppingsId
      __typename
    }
  }
`;
export const updateTopping = /* GraphQL */ `
  mutation UpdateTopping(
    $input: UpdateToppingInput!
    $condition: ModelToppingConditionInput
  ) {
    updateTopping(input: $input, condition: $condition) {
      id
      accountId
      display_name
      maximum_selection_quantity
      minimum_selection_quantity
      prompt_label
      topping_is_complimentary
      category
      slug
      topping_is_required
      topping_options {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      menuToppingsId
      __typename
    }
  }
`;
export const deleteTopping = /* GraphQL */ `
  mutation DeleteTopping(
    $input: DeleteToppingInput!
    $condition: ModelToppingConditionInput
  ) {
    deleteTopping(input: $input, condition: $condition) {
      id
      accountId
      display_name
      maximum_selection_quantity
      minimum_selection_quantity
      prompt_label
      topping_is_complimentary
      category
      slug
      topping_is_required
      topping_options {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      menuToppingsId
      __typename
    }
  }
`;
export const createToppingOption = /* GraphQL */ `
  mutation CreateToppingOption(
    $input: CreateToppingOptionInput!
    $condition: ModelToppingOptionConditionInput
  ) {
    createToppingOption(input: $input, condition: $condition) {
      id
      accountId
      topping_option_in_stock
      topping_option_name
      topping_option_price
      createdAt
      updatedAt
      toppingTopping_optionsId
      __typename
    }
  }
`;
export const updateToppingOption = /* GraphQL */ `
  mutation UpdateToppingOption(
    $input: UpdateToppingOptionInput!
    $condition: ModelToppingOptionConditionInput
  ) {
    updateToppingOption(input: $input, condition: $condition) {
      id
      accountId
      topping_option_in_stock
      topping_option_name
      topping_option_price
      createdAt
      updatedAt
      toppingTopping_optionsId
      __typename
    }
  }
`;
export const deleteToppingOption = /* GraphQL */ `
  mutation DeleteToppingOption(
    $input: DeleteToppingOptionInput!
    $condition: ModelToppingOptionConditionInput
  ) {
    deleteToppingOption(input: $input, condition: $condition) {
      id
      accountId
      topping_option_in_stock
      topping_option_name
      topping_option_price
      createdAt
      updatedAt
      toppingTopping_optionsId
      __typename
    }
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner(
    $input: CreatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    createPartner(input: $input, condition: $condition) {
      partnerName
      slug
      accountId
      devices {
        nextToken
        __typename
      }
      config {
        ordering
        payment
        autoConfirm
        customerSmsNotificationIsEnabled
        partnerSmsNotificationIsEnabled
        partnerPhoneNumber
        pushNotificationEnabled
        live
        clientSlug
        sectionNameMapping
        appConfirmationUrl
        bannerImage
        searchText
        currencyConfig
        categories
        openingHours
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $input: UpdatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    updatePartner(input: $input, condition: $condition) {
      partnerName
      slug
      accountId
      devices {
        nextToken
        __typename
      }
      config {
        ordering
        payment
        autoConfirm
        customerSmsNotificationIsEnabled
        partnerSmsNotificationIsEnabled
        partnerPhoneNumber
        pushNotificationEnabled
        live
        clientSlug
        sectionNameMapping
        appConfirmationUrl
        bannerImage
        searchText
        currencyConfig
        categories
        openingHours
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePartner = /* GraphQL */ `
  mutation DeletePartner(
    $input: DeletePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    deletePartner(input: $input, condition: $condition) {
      partnerName
      slug
      accountId
      devices {
        nextToken
        __typename
      }
      config {
        ordering
        payment
        autoConfirm
        customerSmsNotificationIsEnabled
        partnerSmsNotificationIsEnabled
        partnerPhoneNumber
        pushNotificationEnabled
        live
        clientSlug
        sectionNameMapping
        appConfirmationUrl
        bannerImage
        searchText
        currencyConfig
        categories
        openingHours
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPartnerDevice = /* GraphQL */ `
  mutation CreatePartnerDevice(
    $input: CreatePartnerDeviceInput!
    $condition: ModelPartnerDeviceConditionInput
  ) {
    createPartnerDevice(input: $input, condition: $condition) {
      deviceId
      pushToken
      accountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePartnerDevice = /* GraphQL */ `
  mutation UpdatePartnerDevice(
    $input: UpdatePartnerDeviceInput!
    $condition: ModelPartnerDeviceConditionInput
  ) {
    updatePartnerDevice(input: $input, condition: $condition) {
      deviceId
      pushToken
      accountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePartnerDevice = /* GraphQL */ `
  mutation DeletePartnerDevice(
    $input: DeletePartnerDeviceInput!
    $condition: ModelPartnerDeviceConditionInput
  ) {
    deletePartnerDevice(input: $input, condition: $condition) {
      deviceId
      pushToken
      accountId
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const createAuditLog = /* GraphQL */ `
  mutation CreateAuditLog(
    $input: CreateAuditLogInput!
    $condition: ModelAuditLogConditionInput
  ) {
    createAuditLog(input: $input, condition: $condition) {
      id
      accountId
      changeTime
      user
      field
      update
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAuditLog = /* GraphQL */ `
  mutation UpdateAuditLog(
    $input: UpdateAuditLogInput!
    $condition: ModelAuditLogConditionInput
  ) {
    updateAuditLog(input: $input, condition: $condition) {
      id
      accountId
      changeTime
      user
      field
      update
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAuditLog = /* GraphQL */ `
  mutation DeleteAuditLog(
    $input: DeleteAuditLogInput!
    $condition: ModelAuditLogConditionInput
  ) {
    deleteAuditLog(input: $input, condition: $condition) {
      id
      accountId
      changeTime
      user
      field
      update
      createdAt
      updatedAt
      __typename
    }
  }
`;
