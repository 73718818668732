import React, { useContext, useReducer, useState } from "react";
import { Button, Select, notification } from "antd";
import { formatISO, subDays } from "date-fns";
import { ReloadOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import { AppContext } from "../../Router.js";
import { useFetchOrders, useOrderSubscription } from "../../hooks";
import OrderDetails from "./OrderDetails";
import { apiClient } from "../../Router";
import { updateOrder as UpdateOrder } from "../../graphql/mutations";
import { capitalizeText } from "../../utils/index.js";

const getOrdersByStatus = (orders, status) => {
  return [...orders].filter((order) => order.status === status);
};

const ListOrders = ({ title, orders, updateOrder, partner }) => {
  const getTabItems = (orders, updateOrder, partner) => {
    return (
      <ul className={styles.orderList}>
        {orders.map((order, index) => (
          <OrderDetails
            key={index}
            order={order}
            updateOrder={updateOrder}
            partner={partner}
          />
        ))}
      </ul>
    );
  };

  return (
    <section id="latest-orders">
      <h2>{title}</h2>
      {getTabItems(orders, updateOrder, partner)}
      {orders.length === 0 && (
        <p style={{ textAlign: "center", margin: "30px" }}>No orders found</p>
      )}
    </section>
  );
};

const initialState = {
  orders: [],
  loading: true,
  status: "placed",
  error: false,
  form: { name: "", description: "" },
};

function reducer(state, action) {
  switch (action.type) {
    case "ADD_ORDER": {
      return { ...state, orders: [action.order, ...state.orders] };
    }
    case "RESET_FORM":
      return { ...state, form: initialState.form };
    case "SET_ORDERS":
      return { ...state, orders: action.orders, loading: false };
    case "SET_INPUT":
      return { ...state, form: { ...state.form, [action.name]: action.value } };

    case "UPDATE_ORDER": {
      const { orders } = state;
      const index = state.orders.findIndex((o) => o.id === action.order.id);

      if (index > -1) {
        orders[index].paymentStatus = action.order.paymentStatus;
        orders[index].status = action.order.status;
        orders[index].feedback = action.order.feedback;
      } else {
        orders.push(action.order);
      }

      return { ...state, orders };
    }
    case "SET_FILTER":
      return { ...state, status: action.status };
    case "ERROR":
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}

const Orders = () => {
  const { userAttributes, partner } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [api, contextHolder] = notification.useNotification();
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("placed");
  const now = new Date();
  const yesterday = subDays(now, 1);
  const formattedDate =
    formatISO(yesterday, { representation: "date" }) + "T00:00:00Z";
  const { fetchOrders, isLoading } = useFetchOrders({
    formattedDate,
    dispatch,
    api,
    accountId: userAttributes["custom:accountId"],
    userAttributes,
    userLocation: userAttributes["custom:location"] || null,
  });
  useOrderSubscription({
    dispatch,
    userAttributes,
    api,
    accountId: userAttributes["custom:accountId"],
    userLocation: userAttributes["custom:location"] || null,
  });
  const updateOrder = async (id, value) => {
    const index = state.orders.findIndex((o) => o.id === id);
    const orders = [...state.orders];
    orders[index]["status"] = value;

    const variable = {
      input: { id, updatedBy: userAttributes.username, status: value },
    };
    dispatch({ type: "SET_ORDERS", orders });
    try {
      await apiClient.graphql({
        query: UpdateOrder,
        variables: variable,
      });
      console.log("order successfully updated!");
    } catch (err) {
      console.log("error: ", err);
    }
  };

  const handleStatusChange = (value) => setSelectedOrderStatus(value);
  const refresh = async () => fetchOrders();

  if (!partner) return null;
  if (isLoading) return <div>Loading...</div>;
  const filteredOrders = getOrdersByStatus(state.orders, selectedOrderStatus);
  return (
    <main>
      <div className={styles.spacer}>
        {contextHolder}
        <h1 className={styles.main_text}>Orders</h1>
        <Button
          className={styles.refresh}
          icon={<ReloadOutlined />}
          onClick={refresh}
        />
        <Select
          defaultValue={selectedOrderStatus}
          style={{ width: 120 }}
          className={styles.push_right}
          onChange={handleStatusChange}
          options={[
            { value: "placed", label: "Placed" },
            { value: "confirmed", label: "Confirmed" },
            { value: "cancelled", label: "Cancelled" },
            { value: "transit", label: "Transit" },
            { value: "delivered", label: "Delivered" },
          ]}
        />
      </div>
      <ListOrders
        orders={filteredOrders}
        updateOrder={updateOrder}
        partner={partner}
        title={`${capitalizeText(selectedOrderStatus)} (${filteredOrders.length})`}
      />
    </main>
  );
};

export default Orders;
