import React, { useContext } from "react";
import { AppContext } from "../../Router";

const Navigation = () => {
  const { user, userAttributes, signOut, partner } = useContext(AppContext);
  const handleSignOut = (event) => {
    event.preventDefault();
    signOut();
  };

  return (
    <nav className="header__nav">
      <ul>
        {partner?.config?.ordering && (
          <li>
            <a href="/orders">Orders</a>
          </li>
        )}
        {userAttributes.admin && (
          <li>
            <a href="/menus">Menus</a>
          </li>
        )}
        {user && (
          <li>
            <a data-test-id="logout-link" href="/" onClick={handleSignOut}>
              Logout
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navigation;
