export const config = {
  dev: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_PRIGOxaq7",
    APP_CLIENT_ID: "2ts0jom54p7994l3sif7biab85",
    RELEASE_API_ENDPOINT:
      "https://muxxuk9265.execute-api.eu-west-2.amazonaws.com/prod",
  },
  prod: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_cVNJ6gReC",
    APP_CLIENT_ID: "7qh90c519f04oeqfkcvljiul5m",
    RELEASE_API_ENDPOINT:
      "https://muxxuk9265.execute-api.eu-west-2.amazonaws.com/prod",
  },
};

// export const awsconfig = {
//     aws_appsync_graphqlEndpoint: 'https://wujmm7azyjdzrmb4hpztzhcngq.appsync-api.eu-west-2.amazonaws.com/graphql',
//     aws_appsync_region: 'eu-west-1',
//     aws_appsync_authenticationType: 'API_KEY',
//     aws_appsync_apiKey: 'da2-osoptbui2na6zbppmalfpelwwy',
// };

export const awsconfig = {
  aws_appsync_graphqlEndpoint:
    "https://cv2hvpiak5eqzm4gezf7wesqry.appsync-api.eu-west-2.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-blj7pvadhjeb7b4msbbzlcnweu",
};
