/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrderNumber = /* GraphQL */ `
  query GetOrderNumber($id: ID!) {
    getOrderNumber(id: $id) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const listOrderNumbers = /* GraphQL */ `
  query ListOrderNumbers(
    $filter: ModelOrderNumberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderNumbers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        accountId
        currentOrderNumber
        createdAt
        updatedAt
        id
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listQrcodeLinks = /* GraphQL */ `
  query ListQrcodeScanReports(
    $qrcodeId: String
    $filter: ModelQrcodeScanReportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listQrcodeScanReports(
      qrcodeId: $qrcodeId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        qrcodeId
        accountId
        sessionData {
          scanCount
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrderCurrentNumber = /* GraphQL */ `
  query GetOrderCurrentNumber($accountId: String!) {
    getOrderCurrentNumber(accountId: $accountId) {
      accountId
      currentOrderNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrderCurrentNumbers = /* GraphQL */ `
  query ListOrderCurrentNumbers(
    $accountId: String
    $filter: ModelOrderCurrentNumberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderCurrentNumbers(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        currentOrderNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentConfig = /* GraphQL */ `
  query GetPaymentConfig($accountId: String!) {
    getPaymentConfig(accountId: $accountId) {
      accountId
      paymentToken
      paymentProvider
      paymentEndpointUrl
      paymentCallbackUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPaymentConfigs = /* GraphQL */ `
  query ListPaymentConfigs(
    $accountId: String
    $filter: ModelPaymentConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPaymentConfigs(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        paymentToken
        paymentProvider
        paymentEndpointUrl
        paymentCallbackUrl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      name
      status
      tableNumber
      accountId
      sales {
        accountId
        userClientId
        productId
        name
        price
        quantity
        __typename
      }
      orderNumber
      customerPhoneNumber
      smsNotificationToPartnerSent
      smsNotificationToCustomerSent
      pushNotificationToPartnerSent
      paymentStatus
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        tableNumber
        accountId
        sales {
          accountId
          userClientId
          productId
          name
          price
          quantity
          __typename
        }
        customerDetails {
          roomNumber
          tableNumber
          name
          email
          phone
          __typename
        }
        paymentDetails {
          method
          amount
          currency
          status
          __typename
        }
        waiterDetails {
          waiterID
          name
          __typename
        }
        orderSource {
          type
          name
          sectionName
          __typename
        }
        estimatedTime
        feedback {
          rating
          comment
          __typename
        }
        orderNumber
        customerPhoneNumber
        smsNotificationToPartnerSent
        smsNotificationToCustomerSent
        pushNotificationToPartnerSent
        sessionId
        paymentStatus
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSections = /* GraphQL */ `
  query GetSections($id: ID!) {
    getSections(id: $id) {
      id
      name
      accountId
      numberOfAttendants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSections = /* GraphQL */ `
  query ListSections(
    $filter: ModelSectionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        accountId
        numberOfAttendants
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAttendant = /* GraphQL */ `
  query GetAttendant($id: ID!) {
    getAttendant(id: $id) {
      id
      accountId
      firstName
      lastName
      phoneNumber
      gender
      sections
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAttendants = /* GraphQL */ `
  query ListAttendants(
    $filter: ModelAttendantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttendants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        firstName
        lastName
        phoneNumber
        gender
        sections
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMenu = /* GraphQL */ `
  query GetMenu($id: ID!) {
    getMenu(id: $id) {
      id
      accountId
      description
      name
      inStock
      category
      imageUrl
      subCategoryName
      categoryName
      price
      locationPrices {
        location
        price
        __typename
      }
      availabilityTime
      toppings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMenus = /* GraphQL */ `
  query ListMenus(
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        description
        name
        inStock
        category
        imageUrl
        subCategoryName
        categoryName
        price
        locationPrices {
          location
          price
        }
        availabilityTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTopping = /* GraphQL */ `
  query GetTopping($id: ID!) {
    getTopping(id: $id) {
      id
      accountId
      display_name
      maximum_selection_quantity
      minimum_selection_quantity
      prompt_label
      topping_is_complimentary
      category
      slug
      topping_is_required
      topping_options {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      menuToppingsId
      __typename
    }
  }
`;
export const listToppings = /* GraphQL */ `
  query ListToppings(
    $filter: ModelToppingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listToppings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        display_name
        maximum_selection_quantity
        minimum_selection_quantity
        prompt_label
        topping_is_complimentary
        category
        slug
        topping_is_required
        createdAt
        updatedAt
        menuToppingsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getToppingOption = /* GraphQL */ `
  query GetToppingOption($id: ID!) {
    getToppingOption(id: $id) {
      id
      accountId
      topping_option_in_stock
      topping_option_name
      topping_option_price
      createdAt
      updatedAt
      toppingTopping_optionsId
      __typename
    }
  }
`;
export const listToppingOptions = /* GraphQL */ `
  query ListToppingOptions(
    $filter: ModelToppingOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listToppingOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        topping_option_in_stock
        topping_option_name
        topping_option_price
        createdAt
        updatedAt
        toppingTopping_optionsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($slug: String!) {
    getPartner(slug: $slug) {
      partnerName
      slug
      accountId
      devices {
        nextToken
        __typename
      }
      config {
        ordering
        payment
        autoConfirm
        customerSmsNotificationIsEnabled
        partnerSmsNotificationIsEnabled
        partnerPhoneNumber
        pushNotificationEnabled
        live
        clientSlug
        sectionNameMapping
        appConfirmationUrl
        bannerImage
        searchText
        currencyConfig
        categories
        openingHours
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $slug: String
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPartners(
      slug: $slug
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        partnerName
        slug
        accountId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPartnerDevice = /* GraphQL */ `
  query GetPartnerDevice($deviceId: String!) {
    getPartnerDevice(deviceId: $deviceId) {
      deviceId
      pushToken
      accountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPartnerDevices = /* GraphQL */ `
  query ListPartnerDevices(
    $deviceId: String
    $filter: ModelPartnerDeviceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPartnerDevices(
      deviceId: $deviceId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        deviceId
        pushToken
        accountId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const partnerByAccountId = /* GraphQL */ `
  query PartnerByAccountId(
    $accountId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerByAccountId(
      accountId: $accountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        partnerName
        slug
        accountId
        config {
          ordering
          payment
          autoConfirm
          customerSmsNotificationIsEnabled
          partnerSmsNotificationIsEnabled
          partnerPhoneNumber
          pushNotificationEnabled
          live
          clientSlug
          sectionNameMapping
          appConfirmationUrl
          bannerImage
          searchText
          currencyConfig
          categories
          openingHours
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getAuditLog = /* GraphQL */ `
  query GetAuditLog($id: ID!) {
    getAuditLog(id: $id) {
      id
      accountId
      changeTime
      user
      field
      update
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAuditLogs = /* GraphQL */ `
  query ListAuditLogs(
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        changeTime
        user
        field
        update
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const partnerDeviceByAccountId = /* GraphQL */ `
  query PartnerDeviceByAccountId(
    $accountId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerDeviceByAccountId(
      accountId: $accountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        deviceId
        pushToken
        accountId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
