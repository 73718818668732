import React from "react";
import { Select } from "antd";
import styles from "./index.module.css";

const MenusFiler = ({ options, handleChange, showFilter, selected }) => {
  const mappedOptions = Object.entries(options).map(([key, value]) => {
    return {
      label: `${key} (${value})`,
      value: key,
    };
  });
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      style={{
        width: "100%",
      }}
      optionFilterProp="children"
      filterOption={filterOption}
      showSearch
      size="large"
      data-test-id="header-select-input"
      placeholder="Select a category"
      onChange={handleChange}
      options={mappedOptions}
      value={selected}
      defaultValue={selected}
      className={`${showFilter ? styles.container : styles.invisible}`}
    />
  );
};
export default MenusFiler;
