import React, { useContext, useEffect, useState, useMemo } from "react";
import Navigation from "../Navigation";
import { AppContext } from "../../Router";

const getPartnerName = (partner, userAttributes) => {
  let name = partner.partnerName.toUpperCase();

  if (userAttributes.admin) {
    name += " - Admin";
  }

  if (userAttributes["custom:location"]) {
    name += ` - ${userAttributes["custom:location"].toUpperCase()}`;
  }

  return name;
};

const AppHeader = () => {
  const { partner, userAttributes } = useContext(AppContext);
  const [title, setTitle] = useState("");

  // Ensure hooks are always called
  const memoizedTitle = useMemo(() => {
    if (partner && userAttributes) {
      return getPartnerName(partner, userAttributes);
    }
    return "";
  }, [partner, userAttributes]);

  useEffect(() => {
    if (memoizedTitle) {
      setTitle(memoizedTitle);
    }
  }, [memoizedTitle]);

  // Render loading or null state if necessary
  if (!partner || !userAttributes) {
    return null;
  }

  return (
    <header className="App-header">
      <h1 data-test-id="partner-name">{title}</h1>
      <Navigation />
    </header>
  );
};

export default AppHeader;
